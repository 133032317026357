import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RefObject, useState } from "react";
import { ValueNudger, ValueNudgerProps } from "../ValueNudger";
import React from "react";

const NUDGE_AMOUNT = 1; // change in current value when nudged up or down
const VALUE_TEXT_VERTICAL_PADDING = 3; // pixels above and below the value text

interface CardComponentsProps {
  header: string;
  text?: string;
  value?: number;
  onValueChange?: (newValue: number) => void;
}

export default function CardComponent(props: CardComponentsProps) {
  const { header, text, value, onValueChange } = props;
  const [nudgerVisible, setNudgerVisible] = useState<boolean>(false);
  const [anchorElementRef] = useState<RefObject<any>>(React.createRef());

  // Auto-close timer handling logic
  // - As long as there is a nudger component visible (i.e. anchorEl is not null),
  //   we ensure a timer is running. The initial timer is created in the condition
  //   (anchorEl && !timerId).
  // - If the timer expires then the nudger is closed (anchorEl is set to null) and
  //   the stored timerId is set to null. This is done by calling closeNudgerAndClearTimer.
  // - If the nudger informs the CardComponent that it has been closed (via the user
  //   clicking outside of the nudger), then the timer is cleared and the stored timerId is
  //   set to null. This is done by calling closeNudgerAndClearTimer.
  // - Because closeNudgerAndClearTimer is used in both conditions above, it is responsible
  //   for clearing any potential timer that is running, setting anchorEl to null to
  //   ensure we do not try to bring up another nudger, and setting  the timerId to null
  //   to reset the auto-close timer logic.
  const displayText = text ? text : "-";
  const nudgerPopOutEnabled = displayText !== "-";

  const popOutCaretStyle = {
    color: `var(--bs-gray-${nudgerPopOutEnabled ? "900" : "500"})`,
  };

  const onNudge = (nudgeUp: boolean) => {
    if (value === undefined || !onValueChange) return;
    const newValue = value + NUDGE_AMOUNT * (nudgeUp ? 1 : -1);
    onValueChange(newValue);
  };

  const nudgerProps: ValueNudgerProps = {
    anchorRef: anchorElementRef,
    anchorOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    onClose: () => setNudgerVisible(false),
    onNudge: onNudge,
    id: "seed-spacing-nudger-id",
    transformOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    visible: nudgerVisible,
  };

  const handleNudgerPopOutCaretClick = (event: React.MouseEvent) => {
    if (!nudgerPopOutEnabled) {
      return;
    }
    setNudgerVisible(true);
  };

  const divCaret = onValueChange ? (
    <div ref={anchorElementRef} className="col-2 p-0" onClick={handleNudgerPopOutCaretClick}>
      <FontAwesomeIcon icon={faCaretLeft as any} className="sm" style={popOutCaretStyle} />
    </div>
  ) : null;
  const divText = <div className={`col-${onValueChange ? 8 : 12}`}>{displayText}</div>;
  const divSpacing = onValueChange ? <div className="col-2 p-0"></div> : null;

  return (
    <div className="container p-0">
      <div className="row m-0 px-2 h-100">
        <Card className="m-0 p-0 mt-2">
          <Card.Header as="h6" className="text-center p-1" style={{ backgroundColor: "var(--bs-gray-200)" }}>
            {header}
          </Card.Header>
          <Card.Body className="p-1">
            <ValueNudger {...nudgerProps} />
            <div className="h5 text-center m-0">
              <div style={{ width: "100%", height: VALUE_TEXT_VERTICAL_PADDING }}> </div>
              <div className="row p-0 m-0" style={{ lineHeight: "1.2" }}>
                {divCaret}
                {divText}
                {divSpacing}
              </div>
              <div style={{ width: "100%", height: VALUE_TEXT_VERTICAL_PADDING }}> </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
